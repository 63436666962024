import React, { useState } from "react";
import { Button, Box, Typography, Grid } from "@mui/material";
import questionsData from "./Questions.json";
import Questions from "./QuestionCarousel";
import { ReadingScreen } from "./ReadingScreen";
import HomeIcon from "@mui/icons-material/Home";

interface QuestionsProps {
  onHome: () => void;
}

const MainScreen: React.FC<QuestionsProps> = ({ onHome }) => {
  const [showQuestions, setShowQuestions] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleHome = () => {
    setShowQuestions(false);
  };

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    setShowQuestions(true);
  };

  return (
    <Box p={4}>
           <Button
        onClick={onHome}
        style={{
          marginLeft: "90%",
        }}
      >
        <HomeIcon
          style={{
            cursor: "pointer",
            color: "maroon",
          }}
        />
      </Button>
      {showQuestions ? (
        selectedCategory === "Reading" ? (
          <ReadingScreen onHome={handleHome} />
        ) : (
          <Questions onHome={handleHome} questionsData={questionsData} />
        )
      ) : (
        <div>
          <Button
            variant="contained"
            style={{
              backgroundColor: "maroon",
              color: "white",
              width: "200px",
              height: "60px",
              marginRight: "20px",
            }}
            onClick={() => handleCategoryClick("Reading")}
          >
            Reading Comprehension
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "maroon",
              color: "white",
              width: "200px",
              height: "60px",
            }}
            onClick={() => handleCategoryClick("Sentence")}
          >
            Sentence Completion
          </Button>
        </div>
      )}
    </Box>
  );
};

export default MainScreen;
