import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import TestComponent from "./TestComponent";
import rcQuestions0 from "./reading_comprehension_jsons/rc_2_25_questions_0.json";
import rcQuestions1 from "./reading_comprehension_jsons/rc_2_25_questions_1.json";
import rcQuestions2 from "./reading_comprehension_jsons/rc_2_25_questions_2.json";
import rcQuestions3 from "./reading_comprehension_jsons/rc_2_25_questions_3.json";
import rcQuestions4 from "./reading_comprehension_jsons/rc_2_25_questions_4.json";


interface PassageProps {
  onHome: () => void;
}

export const ReadingScreen: React.FC<PassageProps> = ({ onHome }) => {
  const passages = [
    { name: "Passage 1", data: rcQuestions0 },
    { name: "Passage 2", data: rcQuestions1 },
    { name: "Passage 3", data: rcQuestions2 },
    { name: "Passage 4", data: rcQuestions3 },
    { name: "Passage 5", data: rcQuestions4 },
  ];

  const [selectedPassage, setSelectedPassage] = useState<{
    name: string;
    data: any;
  } | null>(null);
  console.log(passages)
  const handlePassageClick = (passage: { name: string; data: any }) => {
    setSelectedPassage(passage);
  };

  const handleBackClick = () => {
    setSelectedPassage(null);
  };

  return (
    <div>
      <Button
        onClick={onHome}
        style={{
          marginLeft: "90%",
          color: "maroon",
        }}
      >
        Mode Picker
      </Button>
      {selectedPassage ? (
        <div>
          <Button onClick={handleBackClick} style={{ color: "maroon" }}>
            Back to Passages
          </Button>
          <TestComponent
            passage={selectedPassage.data.passage}
            onHome={onHome}
            questionsData={selectedPassage.data.question_and_answers_list}
          />
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          mt={4}
        >
          {passages.map((passage, index) => (
            <Grid item key={index}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "maroon",
                  color: "white",
                  width: "150px",
                  height: "150px",
                }}
                onClick={() => handlePassageClick(passage)}
              >
                {passage.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};
