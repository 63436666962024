import React, { useEffect, useState } from "react";
import "./App.css";
import LandingPage from "./components/LandingPage";
import MainScreen from "./components/MainScreen";
// eslint-disable-next-line import/no-unresolved
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [showQuestions, setShowQuestions] = useState(false);

  const handleStart = () => {
    setShowQuestions(true);
  };

  const handleHome = () => {
    setShowQuestions(false);
  };

  return (
    <div className="App">
      <Analytics />

      {!showQuestions && <LandingPage onStart={handleStart} />}
      {showQuestions && <MainScreen onHome={handleHome} />}
    </div>
  );
}

export default App;
