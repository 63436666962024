import React from "react";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import RCQuestions from "./ReadingComprehension";

interface QuestionData {
  question: string;
  answers: any;
  correct_answer: string;
  explanation: string;
}

interface Props {
  passage: string;
  questionsData: QuestionData[];
  onHome: () => void;
}

const TestComponent: React.FC<Props> = ({ passage, questionsData, onHome }) => {

  console.log(questionsData, passage, "TEST COMPONENT")
  return (
    <div>
      <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="body1" gutterBottom>
          {passage}
        </Typography>
      </Paper>
      <RCQuestions onHome={onHome} questionsData={questionsData} />
    </div>
  );
};

export default TestComponent;
