import React from "react";
import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./LandingPage.css"; // Ensure this CSS file is in the same directory

const LandingPage: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <div className="landing-page">
      <Typography variant="h3" style={{ color: "maroon" }}>
        Meet Mara,
      </Typography>
      <Typography variant="h4" style={{ color: "maroon" }}>
        Your AI tutor for the GRE ©
      </Typography>
      <Typography variant="body1" style={{ marginTop: "20px" }}>
        Mara provides you with curated practice questions that help you increase
        your score on the GRE. Each question comes with an explanation that not
        only highlights what the right answer is, but also tells you how to
        think about questions to improve your skills in the long run! Learn more
        about how it works: Mara is an LLM fine-tuned on thousands of GRE
        questions and incorporates leading advice and frameworks for
        answering/generating GRE questions. GPT-4 has been shown to score a
        169/170 on the GRE Verbal section and we have turned this skill into a
        highly fine-tuned AI question generator/tutor. Finally, we believe
        everyone should be able to ace the GRE and a fine-tuned highly
        specialized tutor and question generator for practice is the first step!{" "}
      </Typography>
      <Button
        variant="contained"
        style={{
          backgroundColor: "maroon",
          color: "white",
          marginTop: "20px",
          marginRight: "20px",
        }}
        onClick={onStart}
      >
        Start Learning
      </Button>
      <Button
        variant="contained"
        style={{
          backgroundColor: "maroon",
          color: "white",
          marginTop: "20px",
        }}
        onClick={() =>
          window.open("https://forms.gle/he2qXiFTJxRJUskz9", "_blank")
        }
      >
        Feedback Form
      </Button>

      <div className="faq-section">
        <Typography variant="h5" style={{ marginTop: "40px" }}>
          FAQ:
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How is this different from ChatGPT?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Mara is specifically fine-tuned on GRE-related content, offering
              more tailored and accurate assistance for GRE preparation compared
              to ChatGPT's general approach. This specialization ensures a
              focused learning experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What are the future features?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Future updates include personalized learning paths, performance
              tracking, and integration of additional educational resources for
              a comprehensive GRE prep experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How can I request features?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Feature requests can be submitted through our user feedback portal
              or directly via our support email, ensuring that Mara evolves in
              line with our users' needs.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What is the review process?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              All content and updates undergo rigorous review by our team of GRE
              experts and educators to ensure accuracy, relevance, and
              effectiveness in GRE preparation.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default LandingPage;
