import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import HomeIcon from "@mui/icons-material/Home";
import "./Questions.css"; // Ensure this CSS file exists

// Props type
interface QuestionsProps {
  onHome: () => void;
  questionsData: Array<{
    question: string;
    answers: string[];
    correct_answer: string;
    explanation: string;
  }>;
}

const RCQuestions: React.FC<QuestionsProps> = ({ onHome, questionsData }) => {
  const [selectedAnswer, setSelectedAnswer] = useState<{
    [key: number]: string;
  }>({});
  const [feedback, setFeedback] = useState<{ [key: number]: string }>({});
  const [openModal, setOpenModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedAnswer({ ...selectedAnswer, [index]: event.target.value });
    const newFeedback = { ...feedback };
    delete newFeedback[index];
    setFeedback(newFeedback);
  };



  const handleSubmit = (index: number) => {
    console.log(questionsData[index].correct_answer === selectedAnswer[index], "Here")

    const isCorrect = selectedAnswer[index] === questionsData[index].correct_answer;
    // Immediately update feedback state with new feedback
    setFeedback(prevFeedback => ({
      ...prevFeedback,
      [index]: isCorrect ? "Correct!" : "Incorrect",
    }));
  };
  

  


  const handleModal = (index: number) => {
    setCurrentQuestion(index);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Carousel
        animation="slide"
        navButtonsAlwaysVisible
        autoPlay={false}
        navButtonsWrapperProps={{ style: { marginBottom: "-40px" } }}
        indicators={false}
      >
        {questionsData.map((qa: any, index: any) => (
          <React.Fragment key={index}>
            <Box p={2}>
              <Typography variant="h6" style={{ color: "maroon" }}>
                {qa.question}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  name={`question_${index}`}
                  value={selectedAnswer[index] || ""}
                  onChange={(e) => handleAnswerChange(e, index)}
                >
                  {qa.answers.map((answer: any, i: any) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FormControlLabel
                        value={answer}
                        control={<Radio />}
                        label={answer}
                      />
                      {selectedAnswer[index] === answer && feedback[index] && (
                        <Typography
                          style={{
                            color:
                              feedback[index] === "Correct!" ? "green" : "red",
                          }}
                        >
                          {feedback[index]}
                        </Typography>
                      )}
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: "maroon",
                color: "white",
                marginTop: "10px",
              }}
              onClick={() => handleSubmit(index)}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              style={{
                borderColor: "maroon",
                color: "maroon",
                marginTop: "10px",
                marginLeft: "20px",
              }}
              onClick={() => handleModal(index)}
            >
              See Explanation
            </Button>
          </React.Fragment>
        ))}
      </Carousel>

      <Modal open={openModal} onClose={handleClose}>
        <Box
          p={2}
          bgcolor="white"
          boxShadow={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h6">Explanation</Typography>
          <Typography variant="body1">
            {
              questionsData[currentQuestion]
                ?.explanation
            }
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default RCQuestions;
