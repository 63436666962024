import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import HomeIcon from "@mui/icons-material/Home";
import "./Questions.css"; // Ensure this CSS file exists

// Props type
interface QuestionsProps {
  onHome: () => void;
  questionsData: any[]; // Define the prop for questions data
}

const Questions: React.FC<QuestionsProps> = ({ onHome, questionsData }) => {
  console.log(questionsData)

  const [questions, setQuestions] = useState<any[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<{
    [key: number]: string;
  }>({});
  const [feedback, setFeedback] = useState<{ [key: number]: string }>({});
  const [openModal, setOpenModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  useEffect(() => {
    setQuestions(
      questionsData.map((question) => ({
        ...question,
        answers: JSON.parse(question.answers.replace(/'/g, '"')),
      }))
    );
  }, [questionsData]);

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedAnswer({ ...selectedAnswer, [index]: event.target.value });
    const newFeedback = { ...feedback };
    delete newFeedback[index];
    setFeedback(newFeedback);
  };

  const handleSubmit = (index: number) => {
    const isCorrect = selectedAnswer[index] === questions[index].correct_answer;
    setFeedback({ ...feedback, [index]: isCorrect ? "Correct!" : "Incorrect" });
  };

  const handleModal = (index: number) => {
    setCurrentQuestion(index);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Button
        onClick={onHome}
        style={{
          marginLeft: "90%",
          color: "maroon",
        }}
      >
        Mode Picker
      </Button>

      <Carousel
        animation="slide"
        navButtonsAlwaysVisible
        autoPlay={false}
        navButtonsWrapperProps={{ style: { marginBottom: "-40px" } }}
        indicators={false}
      >
        {questions.map((question, index) => (
          <React.Fragment key={index}>
            <Box p={2}>
              <Typography variant="h6" style={{ color: "maroon" }}>
                {question.question}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  name={`question_${index}`}
                  value={selectedAnswer[index] || ""}
                  onChange={(e) => handleAnswerChange(e, index)}
                >
                  {question.answers.map((answer: any, i: any) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FormControlLabel
                        value={answer}
                        control={<Radio />}
                        label={answer}
                      />
                      {selectedAnswer[index] === answer && feedback[index] && (
                        <Typography
                          style={{
                            color:
                              feedback[index] === "Correct!" ? "green" : "red",
                          }}
                        >
                          {feedback[index]}
                        </Typography>
                      )}
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: "maroon",
                color: "white",
                marginTop: "10px",
              }}
              onClick={() => handleSubmit(index)}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              style={{
                borderColor: "maroon",
                color: "maroon",
                marginTop: "10px",
                marginLeft: "20px",
              }}
              onClick={() => handleModal(index)}
            >
              See Explanation
            </Button>
          </React.Fragment>
        ))}
      </Carousel>

      <Modal open={openModal} onClose={handleClose}>
        <Box
          p={2}
          bgcolor="white"
          boxShadow={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h6">Explanation</Typography>
          <Typography variant="body1">
            {questions[currentQuestion]?.explanations}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Questions;
